import React from 'react';
import * as links from '../constants/externalPagesLinks';

import { Container, Grid, Typography, Link, Box } from '@mui/material';
import generalAdminLogo from '../assets/ga_univers.png';

const Footer = () => {
  return (
    <footer>
      <Box sx={{ bgcolor: '#f7f8fd', pt: 10, fontWeight: 500, color: '#9c9fa6' }}>
        <Container maxWidth='lg'>
          <Grid container spacing={4}>
            {/* Footer About Section */}
            <Grid item xs={12} md={6}>
              <Box>
                <img src={generalAdminLogo} alt='Footer Logo' style={{ height: '40px' }} />
                <Typography variant='body1' sx={{ mt: 2 }}>
                  Faster, simpler and more cost-efficient than anybody else.
                </Typography>
              </Box>
            </Grid>

            {/* Pages Links Section */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h4' gutterBottom>
                Pages
              </Typography>
              <ul style={{ listStyle: 'none', padding: 0, fontWeight: 500 }}>
                {links.pages.map((link) => {
                  return <LinkComponent key={link.caption} href={`${links.baseURL}/${link.link}`} title={link.caption} />;
                })}
              </ul>
            </Grid>

            {/* Services Links Section */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h4' gutterBottom>
                Services
              </Typography>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {links.services.map((link) => {
                  return <LinkComponent key={link.caption} href={`${links.baseURL}/${link.link}`} title={link.caption} />;
                })}
              </ul>
            </Grid>
          </Grid>

          {/* Copyright and Policy Section */}
          <CopyRightsSection />
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;

function LinkComponent({ href, title }) {
  return (
    <Box component={'li'} sx={{ ml: 0 }}>
      <Link href={href} underline='hover' sx={{ color: '#9c9fa6', fontWeight: 500, lineHeight: 1.5, '&:hover': { color: '#5D56F1' } }}>
        {title}
      </Link>
    </Box>
  );
}

function CopyRightsSection() {
  return (
    <Box sx={{ mt: 4, borderTop: 1, borderColor: 'divider', py: 1.5 }}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography variant='body1' sx={{ color: '#9c9fa6', fontWeight: 500 }}>
            Copyright &copy; 2024 generaladmin. All rights reserved.
          </Typography>
        </Grid>
        <Grid item>
          <Box component='ul' sx={{ display: 'flex', gap: 2, listStyle: 'none', p: 0, m: 0 }}>
            <LinkComponent href={`${links.baseURL}/privacy-policy.html`} title={'Privacy Policy'} />
            <LinkComponent href={`${links.baseURL}/terms-of-service.html`} title={'Terms of Service'} />
            <LinkComponent href={`#`} title={'Go to Top'} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
