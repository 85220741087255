import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { token } from "src/constants/api";
import config from "../config";


function useTokens() {

    function saveTokenToStorage(tokens) {
        localStorage.setItem(config.accessTokenName, tokens.accessToken);
        localStorage.setItem(config.refreshTokenName, tokens.refreshToken);
    }

    const { mutate: callAuthorize } = useMutation({
        mutationFn: async () => {
            const domainName = window.location.hostname;
            const url = new URL(config.backendServiceBaseURL + token.authenticate);
            const response = await axios.post(url, { domainName });
            getTokensCall();
            return response.data;
        },
    })

    const { isError, refetch: getTokensCall } = useQuery({
        queryKey: ['access-token'],
        queryFn: async () => {
            const domainName = window.location.hostname;
            // const tokenURL = token.getAccess.replace(/^\/+/g, '');
            const url = new URL(config.backendServiceBaseURL + token.getAccess);
            const { data } = await axios.post(url, { domainName });
            saveTokenToStorage(data);
            return data;
        },
    });

    useEffect(() => {
        if (isError) {
            callAuthorize();
        }
    }, [isError, callAuthorize])

    return {};
}

export default useTokens;