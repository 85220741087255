export const baseURL = process.env.REACT_APP_WEB_BASE_URL
export const Home = `${baseURL}/`
export const Contact = `${baseURL}/contact-us`
export const Enterprise = `${baseURL}/enterprise`
export const Product = {
	gaForStudents: `${baseURL}/ga-for-students`,
	ourPlatform: `${baseURL}/our-platform`,
	buildingInGA: `${baseURL}/build`,
	dataIntegeration: `${baseURL}/integration`,
	reportingInGA: `${baseURL}/reporting-in-ga`,
};
export const Resources = {
	quickStartGuide: `${baseURL}/quick-start-guide`,
	blogs: `${baseURL}/en/ga_universe`,
	events: `${baseURL}/events`,
	digitalTransformation: `${baseURL}/digital-transformation`,
	docs: `${baseURL}/digital-transformation`,
  pricing: `${baseURL}/#`
};
export const AboutUs = {
  ourVision: `${baseURL}/our-vision`,
  ourMission: `${baseURL}/our-mission`,
  ourHistory: `${baseURL}/our-history`,
  partner: `${baseURL}/partners`
};

export const joinWaitingList =
	`${baseURL}/ts-new-landing-page#csymnewsletter`;


export const pages = [
  { caption: 'Home', link: 'index.html' },
  { caption: 'Problems We Solve', link: 'index.html#problems' },
  { caption: 'Game Changers', link: 'index.html#gamechangers' },
  { caption: 'Services', link: 'index.html#services' },
  { caption: 'Why Choose Us', link: 'index.html#whyus' },
];

export const services = [
  { caption: 'Rapid Application Development', link: 'index.html#services' },
  { caption: 'System Integration', link: 'index.html#services' },
  { caption: 'Data Management', link: 'index.html#services' },
  { caption: 'Analytics, Reporting & Dashboards', link: 'index.html#analytics' },
  { caption: 'AI Integration', link: 'index.html#analytics' },
];

