import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import { unProtectedFetch as axios } from 'src/utils/axios';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'inherit !important',
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover, &:focus': {
    backgroundColor: theme.palette.primary.main,
  },
}));
export default StyledButton;

export const GhoastButton = styled(Button)(({ theme }) => ({
  fontFamily: 'inherit !important',
  border: `none !important`,
  '&:hover, &:focus': {
    fontWeight: '400 !important',
    backgroundColor: theme.palette.primary.light,
  },
}));


export const GoogleSignInButton = ({ onSuccess, onFailure, onSignIn }) => {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Fetch user information from Google
        const res = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
        onSignIn(res)
        // onSuccess(res.data);
      } catch (err) {
        // onFailure(err);
      }
    },
    onError: onFailure,
    scope: 'openid profile email',
  });
  const styles = {
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 0.54)',
      border: '1px solid #dadce0',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
      padding: '0 12px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontFamily: 'Roboto, sans-serif',
      cursor: 'pointer',
      transition: 'box-shadow 0.3s ease, border-color 0.3s ease',
      width: '100%',
    },
    logo: {
      width: '18px',
      height: '18px',
      marginRight: '12px',
    },
    text: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontWeight: '500',
    }
  };
  return (
    <button onMouseEnter={(e) => {
      e.target.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.15)';
      e.target.style.borderColor = '#c0c4c8';
    }}
      onMouseLeave={(e) => {
        e.target.style.boxShadow = '0px 1px 3px rgba(0, 0, 0, 0.1)';
        e.target.style.borderColor = '#DADCE0';
      }}
      style={styles.button} className="custom-google-signup-button" onClick={(e) => {e.preventDefault();login()}}>
      <FcGoogle style={styles.logo} className="google-icon" />
      <span style={styles.text}>Sign Up with Google</span>
    </button>
  );
};
