import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { token } from "src/constants/api";
import config from "../config";


function useRefresh(createCustomerEndpoint) {

    function saveTokenToStorage(tokens) {
        localStorage.setItem(config.accessTokenName, tokens.accessToken);
        localStorage.setItem(config.refreshTokenName, tokens.refreshToken);
    }

    const { mutate: refreshToken } = useMutation({
        mutationFn: async () => {
            const domainName = window.location.hostname;
            const accessToken = localStorage.getItem(config.accessTokenName);
            const refreshToken = localStorage.getItem(config.refreshTokenName);
            const url = new URL(config.backendServiceBaseURL + token.getRefresh);
            const { data } = await axios.post(url, { domainName, refreshToken, accessToken });
            saveTokenToStorage(data);
            createCustomerEndpoint();
            return data;
        },
    })

    return {refreshToken};
}

export default useRefresh;