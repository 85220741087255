export const packages = {
	get: '/package',
};

export const otp = {
	one_time_password: '/one-time-password',
	otp_verify: '/one-time-password/verify',
	otp_resend: '/one-time-password/resend',
};

export const customer = {
	sign_up: '/customer',
	googleTokenVerification:"/customer/google-signin"
};

export const token = {
	getAccess: '/user/getToken',
	getRefresh: '/user/refreshToken',
	authenticate: '/user/authorize',
};