const config = {
	accessTokenName: 'ga-portal-auth-token',
	refreshTokenName: 'ga-portal-refresh-token',
	backendServiceBaseURL:
		process.env.REACT_APP_BACKEND_SERVICE_BASE_URL || 'http://localhost:3000',
	ga_app_URL: process.env.REACT_APP_GA_UNIVERSE_LINK,
	privacyPolicyURL: process.env.REACT_APP_PRIVACY_POLICY_URL,
	termsAndConditionsURL: process.env.REACT_APP_TERMS_AND_CONDITIONS_URL,
	googleClientID: process.env.REACT_APP_GOOGLE_CLIENT_ID
};
export default config;